<template>
    <div class="w100 statisticsbox dp-f">
        <div class="itembox dp-f jc-sb ai-c" v-for='(el,ind) in props.datas' :key="ind" >
            <div class="dp-f ai-c imgbox">
                <img class="w-42 h-42 mr-16" :src="require('./img/'+el.icon)">
                <div>{{ el.title }}</div>
            </div>
            <div class="count">{{ el.count }}</div>
        </div>
    </div>
</template>
<script setup>
import { reactive, ref, unref } from 'vue'
const props = defineProps({
  datas: {
    type: Array, 
    default: [
        { title: '全部合同',count:46,icon:'img1.png' },
        { title: '已完成',count:22,icon:'img2.png' },
        { title: '签署中',count:22,icon:'img3.png' },
        { title: '已过期',count:2,icon:'img4.png' },
    ],
  },//传入的印章数据
})
</script>
<style lang="scss" scoped >
.statisticsbox{
    width: 100%;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #999999;
    .itembox+.itembox{
        margin-left: 1%;
    }
    .itembox{
        // padding:0 50px;
        width: 24.25%;
        height: 120px;
        background: #FFFFFF;
        box-shadow: 0px 0px 16px 0px rgba(211,211,211,0.5);
        border-radius: 2px;
        .imgbox{
            margin-left: 10%;
        }
        .count{
            margin-right: 10%;
            font-size: 46px;
            font-family: DINPro-Regular, DINPro;
            font-weight: 400;
            color: rgba(0,0,0,0.88);
        }
    }
}
</style>